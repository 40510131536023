<template>
  <b-card>

    <validation-observer #default="{handleSubmit}">
      <!--        <b-form @submit.prevent="handleSubmit(submitValidatedEmployeeLocal(me))">-->
      <b-form @submit.prevent="handleSubmit(sendForm)">

        <employee-account
            v-if="me"
            :employee="me"
        />
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 float-right"
            type="submit"
        >
          {{ $t('Edit') }}
        </b-button>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver }   from 'vee-validate'
// import { mixinEmployees } from '@/views/humanResources/employees2/mixinEmployees'

import store            from '@/store'
import Ripple           from 'vue-ripple-directive'
import EmployeeAccount  from '@/components/form/employee/components/_account'
import { useEmployees } from '../../humanResources/employees/useEmployees'

export default {
  directives: {
    Ripple,
  },
  // mixins: [mixinEmployees],
  components: {
    ValidationObserver,
    EmployeeAccount
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const me = computed(() => {
      // console.log(store.getters['user2/getMe'])
      return store.getters['user2/getMe']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedEmployee } = useEmployees()
    // const sendForm = () => {
    //   console.log("in")
    //   emit('submitValidatedEmployeeLocal', me)
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      me,

      // Methods
      submitValidatedEmployee,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    sendForm: function () {
      // this.submitValidatedEmployeeLocal(this.me)

      // console.log(currentSupplierInvoice.value)
      // this.submitValidatedEmployee(this.me)

      store.dispatch('user2/updateUser', this.me)

    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>