<template>
  <b-tabs
      v-if="isMounted"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <icon
            icon="user"
            class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <tab-general/>

    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <icon
            icon="lock"
            class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('ChangePassword') }}</span>
      </template>

      <tab-change-password/>
    </b-tab>
    <!--/ change password tab -->


  </b-tabs>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import TabGeneral        from './components/_general'
import TabChangePassword from './components/_changePassword'

import { getUserData } from '../../utils/utils'
import useAPI from '../../utils/useAPI'
import store  from '../../store'

export default {
  components: {
    TabGeneral,
    TabChangePassword
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isMounted = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const user = computed(() => )

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchUsers2 } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch("user2/getUser", getUserData().id)
        .then(r => {
          // console.log(r)
          isMounted.value = true
        })

    // fetchUsers2({
    //   filters: [
    //
    //   ],
    //   kanbanSortBy: null,
    //   kanbanColumnAmount: null,
    //   listGlobalAmount: null,
    //   page: 1,
    //   sorts: null,
    // }).then(() => {
    //   isMounted.value = true
    // })

    return {
      // Components

      // Data
      isMounted,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>